import React from 'react';
import './Weather.css';
import BrightnessMedium from '@material-ui/icons/BrightnessMediumRounded';
import { withStyles } from '@material-ui/core/styles';

/**
* @render react
* @name Weather
* @description Component that supports fetching and displaying weather forecast
* @example
* <Forecast
*   weather = {city,country,date,temperature,humidity,description,icon}
* />
*/

const styles = {
    weather: {
        // backgroundColor: "black !important",
        '& div': {
            borderBottom: "1pt white solid",
            lineHeight: "2rem",
        },
        
        

    },
    header: {
        

    },
    icon: {
    },
    content:{
        // wordSpacing: "100vw" 
    }
};



const WeatherShort = ({ classes, weather }) => (
    <div classes={{weather: classes.weather}} className={classes.weather}>
        <div>{weather.city}, {weather.country}</div>
        <div>{weather.date}</div>
        <div>{weather.temperature}<sup>o</sup>C {weather.humidity}%</div>
        <div>{weather.description}</div>
        <div><img src={"https://openweathermap.org/img/w/" + weather.icon + ".png"} alt={weather.description} /></div>
    </div>
);


const WeatherFull = ({ classes, weather }) => (
    <div className={classes.weather} >
        <div className={classes.header}>
            <div>
                <img className={classes.icon} src={"https://openweathermap.org/img/w/" + weather.icon + ".png"} alt={weather.description} />
            </div>
            <div className={classes.header.description}>{weather.description}</div>
            <div className={classes.header.location}>{weather.city}, {weather.country}</div>
        </div>
        <div className={classes.content}>
            <span>{weather.time}</span> | <span><b>{weather.temperature}<sup>o</sup>C</b></span> | <span>{weather.humidity}%</span> | <span>{weather.description} <img  className={classes.icon} src={"https://openweathermap.org/img/w/" + weather.icon + ".png"} alt={weather.description} /></span>
        </div>
    </div>
);






const Weather = ({ classes, weather, type = "short" }) => {
    // const { classes } = props;
    // console.log(weather);

    if (type == "short") {
        return <WeatherShort classes={{weather: classes.weather}} weather={weather} />;
    } else {
        return <WeatherFull classes={{weather: classes.weather, header: classes.header, icon: classes.icon, content: classes.content}} weather={weather} />;
    }

};



export default withStyles(styles)(Weather);
