import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'


import { withStyles } from '@material-ui/core/styles';

import RootRef from '@material-ui/core/RootRef';


/**
* @render react
* @name Camera 
* @description Component that utilizes browser camera
* @example
* <Camera />
*/


const styles = {
  block: {
    width: "100%",
    webkitTransform: "scaleX(-1)",
    transform: "scaleX(-1)"
  }

};


const Camera = forwardRef(({ classes }, ref) => {

  const videoEl = useRef(null);


  useImperativeHandle(ref, () => videoEl.current);




  useEffect(() => {
    if (!videoEl) {
      return
    } else {


      // video camera resolution nearest to 1280x720.
      var constraints = { audio: false, video: true };

      navigator.mediaDevices.getUserMedia(constraints)
        .then(function (mediaStream) {
          var video = videoEl.current;
          video.srcObject = mediaStream;
          video.onloadedmetadata = function (e) {
            video.play();
          };
        })
        .catch(function (err) { console.log(err.name + ": " + err.message); }); // always check for errors at the end.
    }
  }, [videoEl])


  return (
    <RootRef rootRef={videoEl}>
      <video classes={{ block: classes.block }} className={classes.block} />
    </RootRef>
  );
})




export default withStyles(styles)(Camera);

// const Camera = ({ classes }, ref) => <forCamera classes={ classes } ref={ref} />;

// see react recompose
// export default compose(forwardRef,withStyles(styles))(Camera);
// export default forwardRef(Camera);
