import React, { useState, useEffect } from 'react';

import { AppBar, Toolbar, Grid, Typography } from '@material-ui/core';
import logo from '../../../logo.png';

import { withStyles } from '@material-ui/core/styles';

import { formatDate } from '../../../utilities/HelperFunctions';
import SensorBoard from '../../molecules/SensorBoard';


import {connect} from 'react-redux';



const styles = (theme) => ({
    box: {
        backgroundColor: "#b0d89eff",
        borderRight: "2pt solid white",
    },
    hideable: {
        backgroundColor: "#e7e8e9ff",
        color: "black",
        borderRight: "2pt solid white",
        [theme.breakpoints.down('md')]: {
            display: "none",
        }
    },

    // SensorBoard styles
    board: {
        backgroundColor: "inherit !important",
        display: "flex",
        flexWrap: "wrap"
    },
    line: {
        borderRight: "1pt solid #0c1829",
    },
    block: {
        flex: "1 0 51%",
    },
    sensorBlock: {
        margin: "0",
        display: "flex",
        flexDirection: "row-reverse"
    },
    sensorTitle: {
        display: "none"
    },
    sensorRight: {

        width: "30%",
        paddingLeft: "10%",
    },
    sensorLeft: {
        textAlign: "center",
        padding: "0",
        // paddingLeft: "5%",
        width: "70%",
    },
    sensorIcon: {
        width: "100%",
    },
    sensorText: {
        fontSize: "0.7rem",
        padding: "0"
    }
});






const sensors = [{
    id: 1,
    title: "Temperature",
    reading: "20oC",
    icon: require("../../../static/img/temperature.svg"),
}, {
    id: 2,
    title: "Humidity",
    reading: "78%",
    icon: require("../../../static/img/humidity.svg"),
}, {
    id: 3,
    title: "Wind speed",
    reading: "5 km/h",
    icon: require("../../../static/img/wind.svg"),
}, {
    id: 4,
    title: "Air Quality",
    reading: "1530 μμο",
    icon: require("../../../static/img/air.svg"),
}];


const indicators = [{
    id: 1,
    title: "Days Running",
    reading: "16",
    icon: require("../../../static/img/days.svg"),
}, {
    id: 2,
    title: "Visitor Number",
    reading: "69",
    icon: require("../../../static/img/visitors.svg"),
}];




const TopMenu = (props) => {
    const {classes} = props;
    
    const [date, setDate] = useState("");

    useEffect(() => {

        getDate();
        setInterval(() => getDate(), 60000);
        // getTime();

    }, [])

    const getDate = () => {
        var date = new Date();

        var dateArray = formatDate(new Date(), "string")

        var displayDate = dateArray[0] + ", " + dateArray[1] + " " + dateArray[2] + " | " + dateArray[3];

        setDate(displayDate);
    }

    return (
        <AppBar position="sticky" color="secondary" style={{ boxShadow: "none", padding: "4vh 2vw 0 2vw" }}>
            <Grid container spacing={8} alignItems="center" style={{ borderRadius: "0" }}>
                <Grid item container ls={4} md={2} justify="center" style={{ borderRadius: "0", padding: "0 4vh" }} >
                    <img src={logo} className="App-logo" alt="logo" />
                </Grid>

                <Grid item container ls={2} md={2} justify="center" style={{ borderRadius: "0", padding: "0", height: "100%" }} className={classes.hideable} >
                    {/* <Toolbar style={{ width: "100%", padding: "0" }}>
                        <SensorBoard sensors={sensors} classes={{ board: classes.board, block: classes.block, line: classes.line, sensorBlock: classes.sensorBlock, sensorTitle: classes.sensorTitle, sensorText: classes.sensorText, sensorLeft: classes.sensorLeft, sensorRight: classes.sensorRight, sensorIcon: classes.sensorIcon }} className={classes.sensor} />
                    </Toolbar> */}

                    <Toolbar>
                        <Typography variant="h4" color="inherit">
                            <div style={{ borderBottom: "2pt solid black", paddingBottom: "0.5rem", textAlign: "center" }}>Sensor Readings</div><div style={{ textAlign: "center" }}><SensorBoard sensors={sensors} classes={{ board: classes.board, block: classes.block, line: classes.line, sensorBlock: classes.sensorBlock, sensorTitle: classes.sensorTitle, sensorText: classes.sensorText, sensorLeft: classes.sensorLeft, sensorRight: classes.sensorRight, sensorIcon: classes.sensorIcon }} className={classes.sensor} /></div>
                        </Typography>
                    </Toolbar>
                </Grid>

                <Grid item container ls={2} md={2} justify="center" className={classes.hideable} >
                    <Toolbar>
                        <Typography variant="h4" color="inherit">
                            <div style={{ borderBottom: "2pt solid black", paddingBottom: "0.5rem" }}>Usage Statistics</div><div style={{ paddingTop: "0.5rem", textAlign: "center" }}><img style={{ height: "1.2rem" }} src={indicators[0].icon} /> {indicators[0].reading} <img style={{ height: "1.2rem" }} src={indicators[1].icon} /> {indicators[1].reading}</div>
                        </Typography>
                    </Toolbar>
                </Grid>
                <Grid item container ls={2} md={2} justify="center" className={classes.hideable} >
                    <Toolbar>
                        <Typography variant="h4" color="inherit">
                            LANGUAGE
                        </Typography>
                    </Toolbar>
                </Grid>
                <Grid item container ls={2} md={4} justify="center" className={classes.box} >
                    <Toolbar>
                        <Typography variant="h4" color="inherit">
                            <div style={{ borderBottom: "2pt solid black", paddingBottom: "0.5rem" }}>{date}</div><div style={{ paddingTop: "0.5rem", textAlign: "center" }}>{props.place}</div>
                        </Typography>
                    </Toolbar>
                </Grid>

            </Grid>
        </AppBar>
    )
};









const mapStateToProps = (state) => ({
    place: state.location.name
  });







export default connect(mapStateToProps)(withStyles(styles)(TopMenu));