import React, { useState, useEffect, useContext } from 'react';
import DashBlock from '../../atoms/DashBlock';

import { withStyles } from '@material-ui/core/styles';

const API_ΚΕΥ = "795acc39ccd5f80941597ff9b53b4e0e";


/**
* @render react
* @name WeatherBlock
* @description WeatherBlock extending DashBlock using openweathermap API 
* @example
* <WeatherBlock
*   title="This is a title"
*   subtitle="This is a subtitle" />
*/



const styles = {
    block: {
    },
    header: {
    },
    title: {
    },
    weather: {

    },
    weatherHeader: {

    },
    weatherHeaderIcon: {

    },
    weatherContent: {

    },
    weatherRow: {
        borderBottom: "1pt black solid"

    },
    weatherIcon: {
        float: "right",

    }

};


const WeatherRows = ({ items, classes }) => {
    const list = items.map((item, index) => {
        if (index%2==0){
            return (
        <div className={classes.row}>
            <span>{item.time}</span> |
            <span><b>{item.temperature}<sup>o</sup>C</b></span> |
            <span>{item.humidity}%</span> |
            <span>{item.description.split(" ")[0]}<img className={classes.icon} src={"https://openweathermap.org/img/w/" + item.icon + ".png"} alt={item.description} /></span>
        </div>);
        }

    }
    );

    return <div className={classes.content}>{list}</div>

}


const FullWeatherBlock = ({ items, classes }) => {

    let maxTemp = Math.max.apply(Math, items.map(function(o) { return o.temperature; }));
    let minTemp = Math.min.apply(Math, items.map(function(o) { return o.temperature; }));

    return (

    <DashBlock title={items[0].day + " | " + items[0].month + " " + items[0].monthDay} classes={{ block: classes.block, header: classes.header, title: classes.title }}>
        <div className={classes.weatherHeader}>
            <div className={classes.weatherMain}>
                <span>
                    <img className={classes.headerIcon} src={"https://openweathermap.org/img/w/" + items[0].icon + ".png"} alt={items[0].description} />
                </span>
                <span className={classes.description}>{items[0].description}</span>
            </div>
            <span className={classes.location}>{items[0].city}, {items[0].country}</span>
            <span className={classes.temperature}>{minTemp} - {maxTemp}<sup>o</sup>C</span>
        </div>
        <WeatherRows items={items} className={classes.row} classes={{ content: classes.weatherContent, icon: classes.weatherIcon, row: classes.weatherRow }} />

    </DashBlock>
)};






export default withStyles(styles)(FullWeatherBlock);