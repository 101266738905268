import React from 'react';
import './ChangeableChip.css';
import Chip from '@material-ui/core/Chip';
import { useState, useEffect } from 'react';

/**
* @render react
* @name ChangeableChip
* @description ChangeableChip component that changes it's appearance on click
* @example
* <ChangeableChip
*   content="Hello World" />
*/




const ChangeableChip = ({content}) => {

    const [enabled, setEnabled] = useState(false);
    const [color, setColor] = useState('default');
    const [outline, setOutline] = useState('outlined');

    const onClick=() =>{
        setEnabled(!enabled);
        if (enabled){
            setColor('default');
            setOutline('outlined');
        }else{
            setColor('primary');
            setOutline('default');
        }
    }

    return (
        <Chip color={color} onClick={() => {onClick()}} variant={outline} label={content}/>
    );


}


export default ChangeableChip;
