import React from 'react';
import 'leaflet/dist/leaflet.css';
import DashBlock from '../../atoms/DashBlock/';
import Map from '../../atoms/Map/Map';
import './MapBlock.css';

import { withStyles } from '@material-ui/core/styles';

/**
* @render react
* @name MapBlock
* @description MapBlock extending DashBlock using OpenStreetMap
* @example
* <MapBlock
*   title="This is a title"
*   subtitle="This is a subtitle" />
*   location={ latitude: 51.505, longitude: -0.09 }
*/

const styles = {
    block: {
        backgroundColor: "#e0af8a",
        border: "1pt solid #e0af8a",
    },
    header: {
        
        '&:before': {
            borderBottom: "49px solid #e0af8a",
        },
    },
    title: {
        color: "#e0af8a"
    }

};


const MapBlock = ({ title, subtitle, location, classes }) => (
    <DashBlock title={title} subtitle={subtitle} classes={{block:classes.block, header: classes.header, title: classes.header}}>
        <Map location={location} name="mapblock"/>
    </DashBlock>
);



// Set default props
MapBlock.defaultProps = {
    title: "Map block title",
    location: { latitude: 51.505, longitude: -0.09 }
};

export default withStyles(styles)(MapBlock);

