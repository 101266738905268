import React from 'react';
import './MapTemplate.css';
import Map from '../../atoms/Map';
import Sidebar from '../../molecules/Sidebar';
import MapToolbar from '../../organisms/MapToolbar';
import { Grid} from '@material-ui/core';


/**
* @render react
* @name MapTemplate
* @description Component that adds a sidebar to the right side
* @example
* <MapTemplate
*   title="Test Title", info="Test Subtitle"
* />
*/

// const MapTemplate = ({ location, title, subtitle, children, onClick }) => {
//     return (
//         <Grid container spacing={24}>
//         <Grid item xs={9}>
//         <Map location={location} name="modalmap" />
//         </Grid>
//         <Grid item xs={3}>
//         <Sidebar title={title} subtitle={subtitle}>
//         {children}
//         </Sidebar>
//         </Grid>
//       </Grid>


        
//     );
// };



const MapTemplate = ({ location, title, info, children, onClick }) => {
    return (
        <div>
            <MapToolbar title="Select POI Layers" info="Select the layers that you want to be shown on the map"/>
            <Map location={location} name="modalmap" />
        </div>


        
    );
};




export default MapTemplate;