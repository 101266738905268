// import { URLSearchParams } from "url";


// API GET fetch result function based on query
export const fetchResult = async (url, data) => {

    var url = new URL(url);
    url.search = new URLSearchParams(data);


    // The parameters we are gonna pass to the fetch function
    // let fetchData = {
    //     method: "POST",
    //     body: data
    // }

    // const api_call = await fetch(url, fetchData);

    const api_call = await fetch(url);

    // const api_call = await fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${location.latitude}&lon=${location.longitude}&appid=${API_ΚΕΥ}&units=metric`);

    return await api_call.json();
}

