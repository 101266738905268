import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {combineReducers, createStore} from 'redux';
import {Provider} from 'react-redux';
import {locationReducer} from './reducers/location';


import SETTINGS from './config/config'

const allReducers = combineReducers({
    location: locationReducer,
    // user: userReducer
});



const store = createStore(allReducers, 
    {
        location: SETTINGS.location,
        user: "Jack Sparrow"
    },
    window.devToolsExtension && window.devToolsExtension()
);

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
