import React, { useState, useEffect, useRef } from 'react'


import { withStyles } from '@material-ui/core/styles';
import Camera from '../Camera';



/**
* @render react
* @name Camera 
* @description Component that utilizes browser camera
* @example
* <PhotoCamera />
*/



const useCameraCountdown = (seconds, canvasEl, videoEl, start) => {
  const [remainingSeconds, setRemainingSeconds] = useState(seconds);
  const [countDownFinished, setCountDownFinished] = useState(false);
  const [image, setImage] = useState(null);

  // console.log(canvasEl.current);


  const takepicture = () => {
    console.log("picture taken");

    const context = canvasEl.current.getContext('2d');
    
    context.width = canvasEl.current.width;
    context.height = canvasEl.current.height;
    context.drawImage(videoEl.current, 0, 0, context.width, context.height);
    var data = canvasEl.current.toDataURL('image/png'); //call timeout call timeout
    setImage(data);
    console.log(data);

    // var context = canvas.getContext(pictureEl);
    // if (width && height) {
    //   canvas.width = width;
    //   canvas.height = height;
    //   context.drawImage(video, 0, 0, width, height);

    //   var data = canvas.toDataURL('image/png');
    //   photo.setAttribute('src', data);
    // } else {
    //   clearphoto();
    // }
  }


  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const tick = async() => {

    await sleep(1000);

    setRemainingSeconds(remainingSeconds - 1);

  }


  const startCountDown = () => {
    tick();
  }


  useEffect(() => {
    if(start){
      tick();
    }
  },[start]);


  useEffect(() => {    
    if (remainingSeconds > 0 && remainingSeconds < seconds){
      tick();
    }else if (remainingSeconds==0){
      takepicture();
      setRemainingSeconds(seconds);
    }
  },[remainingSeconds]);


  return [remainingSeconds, image];


}








const styles = {
  block: {
    width: "100%",
    webkitTransform: "scaleX(-1)",
    transform: "scaleX(-1)"
  },

  canvas: {
    // position: "relative",
    // top: "0",
    // left: "0",
    // zIndex: "100000",
    // webkitTransform: "scaleX(-1)",
    // transform: "scaleX(-1)"
    display: "none"
  }

};






const PhotoCamera = ({ classes, seconds, onStart, shotImageCallback }) => {

  const canvasEl = useRef(null);
  const videoEl = useRef(null);


  // const [countDown, setCountDown] = useState(false);


  const [remainingSeconds, image] = useCameraCountdown(seconds, canvasEl, videoEl, onStart);

  // const remainingSeconds = useCameraCountdown(seconds, canvasEl, videoEl);
  // context.drawImage(videoEl, 0, 0, 640, 480);


  useEffect(() => {
    if (!canvasEl) {
      return
    }
  }, [canvasEl]);


  useEffect(() => {
    shotImageCallback(image);
  }, [image]);

  


  return (
    <div>
      {/* <div style={{ position: "absolute", top: "20%", zIndex: "10", color: "white", fontSize: "30rem" }}>{remainingSeconds}</div> */}
      <div style={{ position:"absolute", top:"50%", left:"65%", transform:"translate(-50%, -50%)", color: "white", fontSize: "30rem", opacity: ".8", zIndex: "1000" }}>{remainingSeconds}</div>
      {/* <Camera ref={videoEl} /> */}
      <Camera innerRef={videoEl} />

      <canvas className={classes.canvas} classes={{ canvas: classes.canvas }} ref={canvasEl} width="640" height="480" />

    </div>
  );
}





export default withStyles(styles)(PhotoCamera);