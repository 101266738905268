import React, { useState } from 'react';

import { Grid, Paper } from '@material-ui/core';
import DashBlock from '../../atoms/DashBlock/';
import WeatherBlock from '../../organisms/WeatherBlock/';
import MapBlock from '../../organisms/MapBlock/';
import DashModal from '../../organisms/DashModal';
import DisplayBlock from '../../molecules/DisplayBlock';
// import Map from './../atoms/Map';
import MapTemplate from '../../templates/MapTemplate';
import Info from '../../atoms/Info';
import Forecast from '../../organisms/Forecast';

import { withStyles } from '@material-ui/core/styles';
import CameraBlock from '../../molecules/CameraBlock';
import CameraModal from '../../molecules/CameraModal/CameraModal';



const styles = (theme) => ({
    area: {
        height: "82.1vh",
        overflow: "hidden",
        padding: "4vh 2vw 4vh 2vw",
        borderRadius: "0",
        backgroundColor: "#0b142eff !important",
        backgroundImage: "linear-gradient(to bottom, rgba(11,20,46,1), rgba(11,20,46,0))",
        [theme.breakpoints.down('md')]: {
            height: "max-content",
        },

    }
});


const items = [{
    id: 1,
    title: "ABOUT",
    subtitle: "You can find information about dentro usage here",
    type: "external",
    content: "https://www.dentro.com.gr",
    media: require("../../../static/img/dentro.png"),
    size: 1
}, {
    id: 2,
    title: "TRAVELGRECO",
    subtitle: "Discover unique greek destinations through our guide!",
    type: "external",
    content: "https://www.travelgreco.com",
    media: require("../../../static/img/travelgreco.png"),
    size: 2
}, {
    id: 3,
    title: "WEATHER",
    // subtitle: "You can view weather info here",
    type: "weather",
    content: "192.168.1.154",
    size: 1
}, {
    id: 4,
    title: "MAP",
    // subtitle: "You can find nearby POIs here",
    type: "map",
    content: "map content",
    size: 2
}, {
    id: 5,
    title: "CAMERA",
    // subtitle: "You can take a photo here",
    type: "camera",
    content: "camera content",
    size: 2
}, {
    id: 6,
    title: "OWNER INFO",
    subtitle: "Municipality of Trikala : A hub of innovation in the heart of Greece",
    type: "info",
    content: "camera content",
    media: require("../../../static/img/owner.png"),
    size: 4
}];


const WidgetArea = ({ location, classes }) => {

    const [modalStatus, setModalStatus] = useState({ modalOpen: false, modalTitle: "Title", modalContent: "Content" })

    const handleOpen = (id) => {
        let title = items[id - 1].title;
        let content = items[id - 1].content;
        var modalContent = <iframe src={content} title="Documents" />;

        console.log(items[id - 1].type)
        switch (items[id - 1].type) {

            // return <li key={item.toString()}>
            //   {item}
            // </li>;
            case "weather":
                modalContent = <div><Forecast location={location} /></div>; break;
            // modalContent += <Forecast location={this.state.location} />; break;
            case "map":
                // console.log("opening map " + id); modalContent = <Map location={this.state.location} name="modalmap" />; break;
                console.log("opening map " + id); modalContent = <MapTemplate location={location} name="modalmap" title="Test Title" />; break;
            case "external":
                modalContent = <iframe src={content} title="Documents" />; break;
            case "camera":
                // modalContent = <Camera />; break;
                modalContent = <CameraModal denyCallback={handleClose} />; break;
            default:
                modalContent = <Info url={content} />

        }

        setModalStatus({
            modalOpen: true,
            modalTitle: title,
            modalContent: modalContent
        });

    };

    const handleClose = () => {
        setModalStatus({
            modalOpen: false
        });

    };



    const listItems = items.map((item) =>

        <Grid item xs={12} sm={3 * item.size} md={3 * item.size} lg={3 * item.size} key={item.id} onClick={() => handleOpen(item.id)}>
            {/* <Grid item xs={12*item.size} sm={3*item.size} md={3*item.size} lg={3*item.size} key={item.id} onClick={() => handleOpen(item.id)}></Grid> */}

            {(() => {
                switch (item.type) {

                    // return <li key={item.toString()}>
                    //   {item}
                    // </li>;
                    case "weather":
                        return <WeatherBlock title={item.title} subtitle={item.subtitle} location={location} />;
                    case "map":
                        return <MapBlock title={item.title} subtitle={item.subtitle} location={location} />;
                    case "external":
                        return <DisplayBlock title={item.title} subtitle={item.subtitle} image={item.media} />;
                    case "info":
                        return <DisplayBlock title={item.title} subtitle={item.subtitle} image={item.media} />;
                    case "camera":
                        return <CameraBlock title={item.title} subtitle={item.subtitle} />;
                    default:
                        return <DashBlock title={item.title} subtitle={item.subtitle} />;
                }
            })()}
        </Grid>
    );




    return (
        <Paper elevation={1} className={classes.area}>
            <Grid container spacing={32}>
                {listItems}
            </Grid >

            <DashModal
                open={modalStatus.modalOpen}
                onClose={handleClose}
                title={modalStatus.modalTitle}
                content={modalStatus.modalContent}
                onCloseButton={handleClose}
                buttonText={"Close"}
            />

        </Paper >
    );



}



export default withStyles(styles)(WidgetArea);
