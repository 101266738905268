import React, { forwardRef, useRef, useImperativeHandle } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { DialogActions, Button, TextField, Grid } from '@material-ui/core';

/**
* @render react
* @name CameraActionFooter
* @description A CameraActionFooter for requesting user action and tacking screenshot
* @example
* <CameraModal
*   title="This is a title"
*   subtitle="This is a subtitle"
*   children="children"
*   onClick=alert("clicked")
* />
*/




const styles = (theme) => ({
});






const CameraActionFooter = ({ classes, fieldDisabled = true, agreeClick, denyClick }) => {


    return (

        <DialogActions className={classes.container}>
            <Grid container
                direction="column"
                alignItems="center">
                <Grid item>
                    <Button color="primary" variant="outlined"  onClick={denyClick}>
                        Deny
            </Button>
                    <Button color="secondary" variant="outlined" onClick={agreeClick}>
                        Agree and take photo
            </Button>
                </Grid>
            </Grid>
        </DialogActions>
    );

}

export default withStyles(styles)(CameraActionFooter);