import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';
import API from '../../config/api';

const URI = API.targets.graphql.remote;

export const client = new ApolloClient({
  uri: URI
});


// API fetch result function based on query
export const fetchResult = (query) => {
    return client.query({
        query: gql`${query}`
    }).then(result => result.data);
}

