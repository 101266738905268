import React from 'react';
import './MapToolbar.css';
import { useState, useEffect } from 'react';
import ToolbarPanel from '../../molecules/ToolbarPanel';
// import {client} from '../../atoms/GraphQLClient';
import { fetchResult } from '../../../utilities/GraphQLClient';
import ChangeableChip from '../../atoms/ChangeableChip';
// import gql from 'graphql-tag';


/**
* @render react
* @name MapToolbar
* @description MapToolbar extending ToolbarPanel with Map filters
* @example
* <MapToolbar
*   title="This is a title"
*   info="This is a description used to help the user"
*   children="This is the content"
* />
*/


const QUERY = `
{
    allPoicategories(treeID: 2){
        id
        name
        icon
    }
}
`;

const MapToolbar = ({ title, info, children, query = QUERY }) => {

    const [POIList, setPOIList] = useState([]);


    // Similar to componentDidMount and componentDidUpdate:
    // Get all categories
    useEffect(() => {
        fetchResult(query).then(data => {
            setPOIList(Object.values(data.allPoicategories));
        });

    }, []);

    const onClick = (key) => {
        console.log(key);
        alert("clicked "+key);
    }
   
    // Render categories name
    let list = POIList.map((item) =>
        <span key={item.id} onClick={() => {onClick(item.id)}}>
        <ChangeableChip content={item.name} />
        </span>
    )

    return (
        <ToolbarPanel title={title} info={info}>
            {list}
        </ToolbarPanel>
    )
};







// // API fetch function
// function fetchTreeCategories() {
//     return client.query({
//         query: gql`
//         {
//             allPoicategories(treeID: 2){
//                 id
//                 name
//             }
//         }
//         `
//     }).then(result => result.data);
// }













// Set default props
MapToolbar.defaultProps = {
    title: "Map Toolbar title",
    info: "Map Toolbar description used to help the user",
};

export default MapToolbar;

