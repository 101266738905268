import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
import './ModalTitle.css'

import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
};


class ModalTitle extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <AppBar className={classes.appBar} color="secondary">
                <Toolbar>
                    <IconButton color="inherit" onClick={this.props.onCloseButton} aria-label="Close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h5" color="inherit" className="flex">
                        {this.props.title}
                    </Typography>
                    {/* <Button color="inherit" onClick={this.props.onCloseButton}>
                        save
            </Button> */}
                </Toolbar>
            </AppBar>);
    }
};

export default withStyles(styles)(ModalTitle);
