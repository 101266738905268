import React from 'react';
import { Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';


/**
* @render react
* @name SensorDisplay
* @description Component that supports displaying data from Sensor 
* @example
* <SensorDisplay
*   ip="192.168.1.154"
* />
*/


const styles = (theme) => ({
    block: {
        margin: "2vh 0",
        height: "100%",
        textAlign: "center"
    },
    title: {
        fontSize: "2rem",
        width: "max-content",
        // textAlign: "center",
        borderBottom: "0.18rem solid #0c1829",
        textTransform: "uppercase",
        padding: "2vh 0",
        [theme.breakpoints.down('md')]: {
            fontSize: "1rem",
        },
    },
    left: {
        width: "65%",
        paddingLeft: "3vw",
        
    },
    right: {
        width: "35%",
    },
    text: {
        fontSize: "2rem",
        fontWeight: "bold",
        textAlign: "center",
        padding: "2vh 0",
        [theme.breakpoints.down('md')]: {
            fontSize: "1rem",
        },
    },
    icon: {
        width: "50%",
        height: "50%"
    }

});




const SensorDisplay = ({ title, data, icon, classes }) => {

    return (
        <Grid container className={classes.block} classes={{ block: classes.block }} alignContent="center" alignItems="center" >
            <Grid item className={classes.left}>
                <div className={classes.title}>{title}</div>
                <div className={classes.text}>{data}</div>
            </Grid>
            <Grid item className={classes.right}>
                <img className={classes.icon} src={icon} />
            </Grid>
        </Grid>
    );

}



export default withStyles(styles)(SensorDisplay);
