import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

/**
* @render react
* @name Map
* @description Map component that renders a map with a given location using OpenStreetMap
* @example
* <Map
*   location={ latitude: 51.505, longitude: -0.09 } name="myMapContainer" />
*/





const Map = ({ location = { latitude: 51.505, longitude: -0.09 }, name="myMapContainer" }) => {

    const [map, setMap] = useState(undefined);

    const mapRef = useRef(null);


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        updateMap();
    }, [location]);



    const updateMap = () => {

        if (mapRef.current == null) {

            mapRef.current = L.map(name, {
                center: [location.latitude, location.longitude],
                zoom: 16,
                layers: [
                    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    }),
                ]
            });


        } else {

            mapRef.current.panTo(new L.LatLng(location.latitude, location.longitude));
        }
    }


    return (
        <div id={name}></div>
    );
};



export default Map;
