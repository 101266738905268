import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import SensorDisplay from '../../atoms/SensorDisplay';

import { withStyles } from '@material-ui/core/styles';


/**
* @render react
* @name SensorBoard
* @description Component that displays data from all Sensors 
* @example
* <SensorBoard
*   ip="192.168.1.154"
* />
*/

const styles = (theme) => ({
    board: {
        width: "100%", 
        backgroundColor: "#ffffff", 
        margin: "0"
    },
    block: {
        fontSize: "0.1em"

    },
    line: {
        borderRight: "0.18rem solid #0c1829",
    },
    sensorΒlock: {

    },
    sensorTitle:{

    },
    sensorLeft:{

    },
    sensorRight:{

    },
    sensorIcon:{

    },
    sensorText:{

    }

});


const SensorBoard = ({ sensors, classes }) => {

    const length = sensors.length;

    const list = sensors.map((item, index) => (
        <Grid item lg={3} md={6} key={index}  className={index==length-1?"":classes.line}>
            <SensorDisplay  title={item.title} data={item.reading} icon={item.icon} className={classes.sensorBlock} classes={{block: classes.sensorBlock, title: classes.sensorTitle, left: classes.sensorLeft, right: classes.sensorRight, icon: classes.sensorIcon, text: classes.sensorText }}/>
        </Grid>
    ));

    return (
        <Grid container className={classes.board} classes={{board:classes.board}} spacing={8}>
                {list}
        </Grid>
    );

}



export default withStyles(styles)(SensorBoard);
