import { Card, CardHeader, CardMedia, CardContent, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import mockdata from './mockdata.json';

import { withStyles } from '@material-ui/core/styles';

const styles = {
    block: {
        backgroundColor: "#ffffff",
        borderRadius: "0",
        height: "100%"
    },
    header: {
        backgroundColor: "#0c142e",
        margin: "5vh 5vw",
        height: "14%",
    },
    title: {
        position: "relative",
        width: "50vw",
        left: "-5vw",
        paddingBottom: "1rem",
        color: "#b0d89e",
        fontWeight: "bold",
        fontSize: "2rem",
        textAlign: "right",
        textTransform: "uppercase",
        borderBottom: "1pt solid #b0d89e",
    },
    content: {
        marginLeft: "5vw",
        height: "47vh",
        color: "#0c142e",
        padding: "0",
    },
    contentTitle: {
        fontWeight: "bold",
        fontSize: "1.9rem",
        padding: "1rem 0"
    },
    text: {
        textAlign: "justify",
        padding: "0 1rem 0 0",
        height: "80%",
        overflowY: "scroll"
    },
    contentFooter: {
        fontWeight: "bold",
        fontSize: "1.2rem",
        marginTop: "1rem",
        textAlign: "center"
    },
    contentFooterLine: {
        borderBottom: "1pt solid #0c142e",
        width: "25%",
        position: "relative",
        top: "0.8rem"
    },
    image: {
        marginRight: "5vw",
        height: "52.4vh"
    },
    footer: {
        backgroundColor: "#0c142e",
        height: "8vh",
        marginTop: "7vh"
    },
    footerTop: {
        position: "relative",
        height: "6vh",
        bottom: "4vh",
        margin: "0 5vw 0 5vw",
        backgroundColor: "#b0d89e"
    }

};


/**
* @render react
* @name Info
* @description Component that renders info from json fetch
* @example
* <Info
*   url="http://www.google.com"
* />
*/

// class Info extends Component {

const Info = ({ url, classes }) => {

    const [data, setData] = useState({
        title: "This is a Title",
        content: "This is some Content",
        image: undefined
    });

    const getInfo = async () => {
        // alert("check");
        const api_call = await fetch(`${url}`);

        // const data = await api_call.json();
        const responseData = mockdata.data;


        setData({
            title: responseData.title,
            subtitle: responseData.subtitle,
            image: responseData.image,
            content: responseData.content,
            website: responseData.website
        });

        // console.log(data);
    }


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // console.log(mockdata);
        getInfo();
    }, []);




    return (
        <Card classes={{ root: classes.block }}>
            <CardHeader className={classes.header} classes={{ root: classes.header, title: classes.title }} title={data.title}/>

            <Grid
                container
                spacing={16}
            // direction="column"
            // alignItems="center"
            // justify="center"
            >
                <Grid item md={12} lg={4}>
                    <CardContent classes={{ root: classes.content }}>
                        <div className={classes.contentTitle}>
                            {data.subtitle}
                        </div>
                        <div className={classes.text}>
                            {data.content}
                        </div>
                        <div className={classes.contentFooter}>
                            <div className={classes.contentFooterLine}></div>
                            {data.website}
                        </div>
                    </CardContent>
                </Grid>
                <Grid item md={12} lg={8}>
                    <CardMedia className={classes.image} classes={{ media: classes.image }} image={data.image} />
                    {/* <img src={data.image} /> */}
                    {/* </CardMedia> */}
                </Grid>

            </Grid>
            <div className={classes.footer}>
                <div className={classes.footerTop}></div>
            </div>

        </Card>
    );

}


export default withStyles(styles)(Info);