import React from 'react';
import './ToolbarPanel.css';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

/**
* @render react
* @name ToolbarPanel
* @description Component that adds a ToolbarPanel to the right side
* @example
* <ToolbarPanel
*   title="Test Title", subtitle="Test Subtitle"
* />
*/



const ToolbarPanel = ({ title, info, children, onClick }) => {
    return (
        <div>
            <ExpansionPanel defaultExpanded>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                        <Typography>{title}</Typography>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div />
                    <div>
                        {children}
                    </div>
                    <div>
                        <Typography variant="caption">
                            {info}
                        </Typography>
                    </div>
                </ExpansionPanelDetails>
                <Divider />
                <ExpansionPanelActions>
                    <Button size="small">Cancel</Button>
                    <Button size="small" color="primary">
                        Save
          </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        </div>
    );
};




export default ToolbarPanel;
