import React from 'react';
import { Card, CardHeader, CardMedia, CardContent } from '@material-ui/core';
import './DashBlock.css';

import { withStyles } from '@material-ui/core/styles';



/**
* @render react
* @name DashBlock
* @description A Dashboard block displayed as a Tile
* @example
* <DashBlock
*   title="This is a title"
*   subtitle="This is a subtitle"
*   children="children"
*   onClick=alert("clicked")
* />
*/



const styles = (theme) => ({
    block: {
        border: "1pt solid #b0d89eff",
        borderRadius: "0",
        cursor: "pointer",     
        height: "25vh",
        [theme.breakpoints.down('md')]: {
            height: "50vh",
        }
        // zIndex: "-1"           
    },
    header: {
        backgroundColor: "#0c1529ff",
        position: "relative",
        fontSize: "75%",

        '&:before': {
            position: "absolute",
            content: "''",
            top: "0",
            right: "0",
            borderBottom: "49px solid #b0d89eff",
            borderLeft: "49px solid #0c1529ff",
        },
    },
    subheader: {},
    title: {
        color: "#b0d89eff",
        fontWeight: "bold",
    },
    content: {
        color: "white"
    }
    
});




const DashBlock = ({ title, subtitle, classes, children, onClick }) => {
    // const mytitle = <div><BrightnessMedium/><CardHeader className="header" title={<BrightnessMedium/>{title}} /></div>;

    return (<Card  classes={{root: classes.block}} onClick={onClick}>

        <CardHeader title={title} classes={{root: classes.header, subheader: classes.subheader, title: classes.title}} 
        subheader={subtitle} /> 
        <CardContent classes={{root: classes.content}}>
            {children}
        </CardContent>
    </Card>);
};

export default withStyles(styles)(DashBlock);