import React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = {
    block: {
        backgroundColor: "#ffffff",
        borderRadius: "0",
        height: "100%",
        width: "99.5%"
    },
    content: {
        padding: "5vh 5vw"
    },
    leftContent: {
        height: "100%",
        color: "#0c142e",
        padding: "0",
    },
    rightContent: {
        marginLeft: "5%",
        height: "100%",
        color: "#0c142e",
        padding: "0",
    }
};


/**
* @render react
* @name InfoLayout
* @description Component that presents it's children in two rows
* @example
* <Info
*   url="http://www.google.com"
* />
*/


const InfoLayout = ({ items, classes, leftArea=4, rightArea=8 }) => {



    return (
        <div className={classes.block} classes={{ root: classes.block }}>

            <Grid
                container
                spacing={16}
                className={classes.content}
                classes={{ content: classes.content }}
            >
                <Grid item md={12} lg={leftArea}>
                    <div className={classes.leftContent} classes={{ left: classes.leftContent }}>
                        {items[0].element}
                    </div>
                </Grid>
                <Grid item md={12} lg={rightArea}>
                <div className={classes.rightContent}  classes={{ right: classes.rightContent }}>
                        {items[1].element}
                    </div>
                </Grid>

            </Grid>

        </div>
    );

}


export default withStyles(styles)(InfoLayout);