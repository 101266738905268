import React, { useState, useEffect, useContext } from 'react';
import DashBlock from '../../atoms/DashBlock/';
import Weather from '../../atoms/Weather';
import './WeatherBlock.css'

import { withStyles } from '@material-ui/core/styles';
import { formatDate } from '../../../utilities/HelperFunctions';
import { AppContextProvider } from '../../../AppContext';

import {fetchLatestWeather} from '../../../utilities/requests';

const API_ΚΕΥ = "795acc39ccd5f80941597ff9b53b4e0e";


/**
* @render react
* @name WeatherBlock
* @description WeatherBlock extending DashBlock using openweathermap API 
* @example
* <WeatherBlock
*   title="This is a title"
*   subtitle="This is a subtitle" />
*/



const styles = {
    block: {
        backgroundColor: "#92b3b6",
        border: "1pt solid #92b3b6",
    },
    header: {
        '&:before': {
            borderBottom: "49px solid #92b3b6",
        },
    },
    title: {
        color: "#92b3b6"
    }

};



const WeatherBlock = ({ title, subtitle, location, classes }) => {


    const [data, setData] = useState({
        city: undefined,
        country: undefined,
        temperature: undefined,
        humidity: undefined,
        description: undefined,
        icon: undefined
    });


    const getWeather = async() => {
        setData(await fetchLatestWeather(location))
    }

    useEffect(() => {
        // setData({
        //     latitude: location.latitude,
        //     longitude: location.longitude
        // });

        getWeather();

    },[location]);

    return (
        <DashBlock title={title} subtitle={subtitle} classes={{ block: classes.block, header: classes.header, title: classes.title }}>
            <AppContextProvider value={{ locationName: data.city + ", " + data.country }}>
                <Weather weather={data} />
            </AppContextProvider>
        </DashBlock>
    );



};


export default withStyles(styles)(WeatherBlock);