import {UPDATE_LOCATION} from '../actions/location';

export const locationReducer = (state='', {type, payload} ) => {
    switch (type){
        case UPDATE_LOCATION:
            return payload.location;
        default:
            return state;
    }

}

// export locationReducer;