import React from 'react';
import { useState, useEffect } from 'react';
import Weather from '../../atoms/Weather';
import { Grid, Paper } from '@material-ui/core';
import DashBlock from '../../atoms/DashBlock';
import FullWeatherBlock from '../../organisms/FullWeatherBlock';

import { formatDate } from '../../../utilities/HelperFunctions';

import { withStyles } from '@material-ui/core/styles';
import SensorBoard from '../../molecules/SensorBoard';
// import './Forecast.css';

const API_ΚΕΥ = "795acc39ccd5f80941597ff9b53b4e0e";

/**
* @render react
* @name Forecast
* @description Component that supports fetching and displaying weather forecast
* @example
* <Forecast
*   location = {latitude,longitude}
* />
*/



const styles = (theme) => ({
    block: {
        backgroundColor: "#ffffff",
        border: "1pt solid #ffffff",
        height: "47vh",
        // height: "max-content",
        cursor: "default",
        [theme.breakpoints.down('md')]: {
            height: "max-content",
        }
    },
    header: {
        '&:before': {
            borderBottom: "49px solid #ffffff",
        },
    },
    title: {
        color: "#ffffff"
    },
    weather: {
        cursor: "default"
    },
    weatherHeader: {
        color: "#0c1529",
        padding: "1rem",
        borderBottom: "1pt solid #0c1529",
    },
    weatherMain: {
        textAlign: "center",
        padding: "1rem"

    },
    weatherHeaderIcon: {
        width: "35%"
    },
    weatherDescription: {
    },
    weatherLocation: {
        fontSize: "1rem",
        fontWeight: "bold",
        verticalAlign: "text-bottom",
    },
    weatherTemperature: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        verticalAlign: "text-bottom",
        float: "right"
    },
    weatherContent: {
        color: "#0c1529",
        fontSize: "80%",
        lineHeight: "3rem",
        // borderBottom: "1pt solid #0c1529",

    },
    weatherIcon: {
        // height: "5px"
        height: "3rem",
        verticalAlign: "middle",
        float: "right"

    },
    weatherRow: {
        color: "#0c1529",
        fontSize: "60%",
        lineHeight: "3rem",
        borderBottom: "1pt solid #0c1529",
    },

    sensorBoard: {
        width: "93vw",
        // height: "25vh",
        padding: "3vh 0",
        backgroundColor: "#ffffff",
        margin: "0 3vw"
    }



});


const WeatherList = ({ list, city, classes }) => {
    var items = [];


    if (list != undefined) {
        items = list.map(weather => ({
            city: city,
            country: weather.sys.country,
            temperature: Math.round(weather.main.temp),
            humidity: weather.main.humidity,
            description: weather.weather[0].description,
            icon: weather.weather[0].icon,
            // date: new Date(weather.dt * 1000).toString(),
            // date: formatDate(new Date(weather.dt * 1000)),
            time: formatDate(new Date(weather.dt * 1000))[3],
            day: formatDate(new Date(weather.dt * 1000))[0],
            monthDay: formatDate(new Date(weather.dt * 1000))[1],
            month: formatDate(new Date(weather.dt * 1000), "string")[2],
            date_unix: new Date(weather.dt * 1000)
        }));
    }

    const listItems = items;
    const today = new Date().getDay();

    var days = [];

    for (let i = 0; i < 5; i++) {
        days[i] = listItems.filter(item => item.date_unix.getDay() == (today + i) % 7);
    }



    const torender = days.map((day, index) => {
        if (day[0]) {

            // return <Grid item xs={12} sm={6} md={4} lg={2}>
            //     <DashBlock title={day[0].day+"| "+day[0].month+" "+day[0].monthDay} classes={{ block: classes.block, header: classes.header, title: classes.title }}>
            //         <div className={classes.blockHeader}>
            //             <div>
            //             <span>
            //                 <img className={classes.icon} src={"https://openweathermap.org/img/w/" + day[0].icon + ".png"} alt={day[0].description} />
            //             </span>
            //             <span className={classes.description}>{day[0].description}</span>
            //             </div>
            //             <span className={classes.location}>{day[0].city}, {day[0].country}</span>
            //             <span className={classes.temperature}>{day[0].temperature}<sup>o</sup>C</span>
            //         </div>

            //         {
            //             day.map((item) =>
            //                 <Weather weather={item} classes={{ weather: classes.weather, header: classes.weatherHeader, icon: classes.weatherIcon, content: classes.weatherContent }} type="full" />
            //             )
            //         }
            //     </DashBlock>
            // </Grid>


            return (
                <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FullWeatherBlock items={day} classes={{ block: classes.block, header: classes.header, title: classes.title, weather: classes.weather, weatherHeader: classes.weatherHeader, headerIcon: classes.weatherHeaderIcon, description: classes.weatherDescription, weatherMain: classes.weatherMain, icon: classes.weatherIcon, weatherContent: classes.weatherContent, location: classes.weatherLocation, temperature: classes.weatherTemperature, row: classes.weatherRow }} />
                </Grid>
            )
        }
    });

    return (
        <Grid container spacing={8} style={{ justifyContent: "space-evenly", margin: "7vh 0", width:"99vw" }}>
            {torender}
        </Grid>
    );
}








const sensors = [{
    id: 1,
    title: "Temperature",
    reading: "20 oC",
    icon: require("../../../static/img/temperature.svg"),
}, {
    id: 2,
    title: "Humidity",
    reading: "78%",
    icon: require("../../../static/img/humidity.svg"),
}, {
    id: 3,
    title: "Wind speed",
    reading: "5 km/h",
    icon: require("../../../static/img/wind.svg"),
}, {
    id: 4,
    title: "Air Quality",
    reading: "1530 μμο",
    icon: require("../../../static/img/air.svg"),
}];







const Forecast = ({ location, classes }) => {
    const [list, getData] = useState(undefined);
    const [city, getCity] = useState(undefined);


    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetchForecastData({ location }).then(data => {
            getData(data.list);
            getCity(data.city.name);
        });

    }, []);


    return (
        <Grid container direction="column">
            <Grid item>
                <WeatherList list={list} city={city} classes={classes} />
            </Grid>
            <Grid item>
                <Paper className={classes.sensorBoard} >
                    <SensorBoard sensors={sensors} />
                </Paper>
            </Grid>
        </Grid>
    );

}


// API fetch function
function fetchForecastData({ location }) {
    // console.log(location);
    return fetch(`https://api.openweathermap.org/data/2.5/forecast?lat=${location.latitude}&lon=${location.longitude}&appid=${API_ΚΕΥ}&units=metric`)
        .then(response => response.json());
}



export default withStyles(styles)(Forecast);
