import { fetchResult } from './RESTClient';
import API from '../config/api';
import { formatDate } from '../utilities/HelperFunctions';


//Get latest weather from OpenWeatherMaps API
export const fetchLatestWeather = async (location) => {
    let requestData = {
        lat: location.latitude,
        lon: location.longitude,
        units: "metric",
        appid: API.targets.weather.key
    };

    let data = await fetchResult(API.targets.weather.remote, requestData);

    var date = new Date(data.dt * 1000);
    var dateArray = formatDate(date, "string");
    var displayDate = dateArray[0] + ", " + dateArray[1] + " " + dateArray[2] + " " + dateArray[3];

    return {
        city: data.name,
        country: data.sys.country,
        temperature: Math.round(data.main.temp),
        humidity: data.main.humidity,
        description: data.weather[0].description,
        icon: data.weather[0].icon,
        date: displayDate
    };
};

//Get location area approximation by fetching OpenWeatherMaps API 
export const fetchLocationName = async (location) => {
    let requestData = {
        lat: location.latitude,
        lon: location.longitude,
        units: "metric",
        appid: API.targets.weather.key
    };

    let data = await fetchResult(API.targets.weather.remote, requestData);

    return data.name + ", " + data.sys.country;
};
