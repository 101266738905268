import React from 'react';
import {CardMedia } from '@material-ui/core';
import DashBlock from '../../atoms/DashBlock';
import './DisplayBlock.css';

import { withStyles } from '@material-ui/core/styles';

/**
* @render react
* @name DisplayBlock
* @description A DisplayBlock block displayed as an image Gallery
* @example
* <DisplayBlock
*   title="This is a title"
*   subtitle="This is a subtitle"
*   children="children"
*   onClick=alert("clicked")
* />
*/



const styles = (theme) => ({
    block: {
        backgroundColor: "black",
        border: "none",
        fontFamily: 'UniNeue',
        // display: "block"
        
    },
    header: {
        display: "none",
        // display: "block",
        // // border: "1px solid white",
        // backgroundColor: "transparent",
        // height: "24.5vh",
        // width: "22vw",
        // position: "absolute",

        // '&:before': {
        //     border: "none",
        //     backgroundColor: "transparent",
        // },
    },
    subheader: {
        color: "black",
        backgroundColor: "#e7e8e9",
        fontSize: "100%",
        padding: "1em",
        position: "absolute",
        bottom: "0px"

    },
    title: {
        color: "white",
        backgroundColor: "black"
    },
    content: {
        padding: "0px",
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        height: "100%"
    },
    media: {
        height: "100%",
        // position: "relative",
        // top: "-50px"
    }

});




const DisplayBlock = ({ title, subtitle, classes, image, onClick }) => {
    // const mytitle = <div><BrightnessMedium/><CardHeader className="header" title={<BrightnessMedium/>{title}} /></div>;

    return (
        <DashBlock  classes={{ block: classes.block, header: classes.header, subheader: classes.subheader, title: classes.title, content: classes.content}} >
        {/* <div classes={{title:classes.title}} style={{width:"inherit"}}>{title}</div> */}
        <div style={{backgroundColor: "none", fontSize: "12px", fontWeight: "bold", padding: "1.7vh 1vw", position: "absolute"}}>{title}</div>
        
        <CardMedia classes={{root: classes.media}} image={image} />
        <div classes={{subheader:classes.subheader}} style={{backgroundColor: "#e7e8e9", color: "black", padding: "1rem"}}>{subtitle}</div>
        
        </DashBlock>

    );
};

export default withStyles(styles)(DisplayBlock);