export const UPDATE_LOCATION = 'location:updateLocation';

export const updateLocation = (newLocation) => {
    return {
        type: UPDATE_LOCATION,
        payload: {
            location: newLocation
        }
    }

};