import React, { useState, useEffect, useContext } from 'react';

import './App.css';


import TopMenu from './components/atoms/TopMenu/TopMenu';
import WidgetArea from './components/organisms/WidgetArea';

// in order to apply custom theme
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { theme } from './utilities/ThemeProvider';

import { fetchLocationName } from './utilities/requests';
import {isMobile} from './utilities/HelperFunctions/HelperFunctions';

import { AppContext, AppContextProvider } from './AppContext';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {updateLocation} from './actions/location';




const App = (props) => {

  // const [location, setLocation] = useState({ latitude: 51.505, longitude: -0.09 });
  // const [locationName, setLocationName] = useState("City of London, GB");

  // const updateLocation = async (location) => {
  //   setLocation(location)
  //   setLocationName(await fetchLocationName(location));
  // }

  const handleLocation = async () => {

    if (isMobile()){

      navigator.geolocation.getCurrentPosition(
        async function success(position) {

          // updateLocation(position.coords);
          
          const name = await fetchLocationName(position.coords);
          props.onUpdateLocation({name: name, coordinates: {latitude: position.coords.latitude, longitude: position.coords.longitude}})

          console.log("%c using browser location", 'background: #222; color: #bada55');

        },
        function error() {
          console.log("%c using default location", 'background: #222; color: #bada55');
        }
      );

    }

  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    handleLocation();
  }, []);



  return (
    <MuiThemeProvider theme={theme}>
      <TopMenu/>
      <WidgetArea location={props.location.coordinates} />
    </MuiThemeProvider>
  );
}





const mapStateToProps = (state) => ({
  location: state.location,
  user: state.user
});


const mapActionsToProps = (dispatch) => {
  return bindActionCreators({
    onUpdateLocation: updateLocation
  }, dispatch);
};



export default connect(mapStateToProps, mapActionsToProps)(App);
