import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';


// custom MuiTheme
export const theme = createMuiTheme({
    palette: {
        type: "dark",
        common: {
            black: "#000",
            white: "rgba(255, 255, 255, 1)"
        },
        background: {
            paper: "rgba(176, 216, 158, 1)",
            default: "rgba(10, 24, 53, 1)"
        },
        primary: {
            light: "rgba(197, 233, 180, 1)",
            main: "rgba(176, 216, 158, 1)",
            dark: "rgba(146, 192, 127, 1)",
            contrastText: "rgba(255, 255, 255, 1)"
        },
        secondary: {
            light: "rgba(146, 179, 182, 1)",
            main: "rgba(12, 24, 41, 1)",
            dark: "rgba(5, 13, 22, 1)",
            contrastText: "#fff"
        },
        error: {
            light: "#e57373",
            main: "rgba(241, 86, 113, 1)",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(255, 255, 255, 1)",
            secondary: "rgba(0, 0, 0, 1)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)"
        }
    },
    typography: {
        fontFamily: 'UniNeue',
        fontSize: '100%'
    },

    overrides: {
        MuiThemeProvider: {
            // backgroundColor: "rgba(11,20,46,1)"
        },
        MuiAppBar: {
            colorDefault: {
                // backgroundColor: "rgba(11,20,46,1)",
                // backgroundColor:"rgba(0,0,0,0.0)",
                // color: "white",
                fontWeight: "bolder",
                align: "right"
            },

        },
        // MuiCardHeader: {
        //     root: {
        //         // backgroundColor: "#0c1529ff",
        //         position: "relative",
        //         '&:before': {
        //             position: "absolute",
        //             content: "''",
        //             top: "0",
        //             right: "0",
        //             borderBottom: "49px solid #b0d89eff",
        //             borderLeft: "49px solid #0c1529ff",
        //         }
        //     },
        //     title: { // Name of the rule
        //         // color: '#b0d89eff', // Some CSS
        //         fontWeight: "bolder",
        //         fontSize: "12px"
        //     },


        // },


        // MuiCard: {
        //     root: {
        //         fontFamily: "UniNeue",
        //         fontSize: "16px",
        //         color: "white",
        //         // backgroundColor: "#b0d89eff",
        //         border: "1pt solid #b0d89eff",
        //         borderRadius: "0"
        //     }
        // }
    },
});

