import React, {useRef} from 'react';
import DashBlock from '../../atoms/DashBlock';
import Camera from '../../atoms/Camera';

import { withStyles } from '@material-ui/core/styles';

/**
* @render react
* @name CameraBlock
* @description A DisplayBlock block displayed as an image Gallery
* @example
* <DisplayBlock
*   title="This is a title"
*   subtitle="This is a subtitle"
*   children="children"
*   onClick=alert("clicked")
* />
*/



const styles = {
    block: {
        // backgroundColor: "#e0af8a",
        border: "1pt solid #b0d89e",
    },
    header: {
        
        '&:before': {
            borderBottom: "49px solid #b0d89e",
        },
    },
    title: {
        color: "#b0d89e"
    }

};




const CameraBlock = ({ title, subtitle, classes, image, onClick }) => {
    const videoEl = useRef();

    return (
        <DashBlock title={title} subtitle={subtitle} classes={{block:classes.block, header: classes.header, title: classes.header}}>
            <Camera ref={videoEl}/>
        </DashBlock>
    );
};

export default withStyles(styles)(CameraBlock);