import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, Slide } from '@material-ui/core';
import './DashModal.css'

import ModalTitle from '../../atoms/ModalTitle'


  

function Transition(props) {
    return <Slide direction="up" {...props} />;
}



const ModalContent = ({ content }) => (
    <DialogContent className="content">
            {content}
    </DialogContent>
);

const ModalActions = ({ onCloseButton, buttonText }) => (
    <DialogActions>
        <Button onClick={onCloseButton} color="primary">
            {buttonText}
        </Button>
    </DialogActions>
);


/**
* @render react
* @name DashModal
* @description Modal for Dashboard with title, content, actions
* @example
* <DashModal
*   title="This is a title"
*   content="This is the content"
*   buttonText="Close"
* />
*/





const DashModal = ({ open, onClose, title, content, onCloseButton }) => (
    <Dialog style={{padding: "0"}} 
        open={open}
        fullScreen
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
    >
        {/* <Paper className="centered" elevation={5} style={{width:"95vw",height: "95vh"}}> */}



        <ModalTitle title={title} onCloseButton={onCloseButton} />

        <ModalContent content={content} />

        {/* <ModalActions onCloseButton={onCloseButton} buttonText={buttonText} /> */}

    </Dialog>
);

export default DashModal;
