import * as React from "react";

const AppContext = React.createContext({
    locationName: "Alli Meria, Greece",
    language: "el",
    treeID: 1
});

const AppContextProvider = AppContext.Provider;
const AppContextConsumer = AppContext.Consumer;

export {AppContext, AppContextProvider, AppContextConsumer};